import { Http } from "../../service/http";
import { SET_NEWS_LAST, REMOVE_NEWS_LAST, ERROR_NEWS_LAST } from "../types";
import { API } from "../../constants";
import { getDateNews } from "../../utils";

export const getNewsTop = () => {
  return async (dispatch) => {
    const data = await Http.get(`${API}/news/top`);
    if (data && !data.error) {
      const regex = /(<([^>]+)>)/gi;
      data.forEach((d) => {
        d.date = getDateNews(d.date);
        d.sm_text = d.sm_text.replace(regex, "");
      });

      dispatch(setNewsTop(data));
    } else {
      dispatch(errorNewsTop());
    }
  };
};

export const removeNewsTop = () => {
  return { type: REMOVE_NEWS_LAST };
};

const setNewsTop = (payload) => {
  return { type: SET_NEWS_LAST, payload };
};

const errorNewsTop = () => {
  return { type: ERROR_NEWS_LAST };
};
