import { CLOSE_DIALOG_SUBSCRIPTION, OPEN_DIALOG_SUBSCRIPTION } from "../types";

export const openDialogSubscription = () => {
  return (dispatch, getState) => {
    const deadline = getState().transfer.rentPlayers;
    const payload = { deadline };
    dispatch(openSubscription(payload));
  };
};

export const closeDialogSubscription = () => {
  return { type: CLOSE_DIALOG_SUBSCRIPTION };
};

const openSubscription = (payload) => {
  return { type: OPEN_DIALOG_SUBSCRIPTION, payload };
};
