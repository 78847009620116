import styled from "styled-components";

export default styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 16px;
  white-space: nowrap;
  color: #000;

  p {
    opacity: 0.4;
    line-height: 16px;
    letter-spacing: 0.01em;
  }
`;
