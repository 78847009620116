import ButtonHamburger from "../ui/ButtonHamburger";
import { useHeaderTopLeft } from "./use-header-top-left";

const HeaderTopLeft = () => {
  const { clicked } = useHeaderTopLeft();

  return <ButtonHamburger clicked={clicked} />;
};

export default HeaderTopLeft;
