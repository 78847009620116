import { Http } from "../../service/http";
import { SET_BEST_BETS, REMOVE_BEST_BETS, ERROR_BEST_BETS } from "../types";
import { API } from "../../constants";

export const getBestBets = () => {
  return async (dispatch) => {
    const data = await Http.get(`${API}/best/bets`);
    if (data && !data.error) {
      data.forEach((d) => {
        d.date = `${d.date.slice(8, 10)}.${d.date.slice(5, 7)}`;
      });
      dispatch(setBestBets(data));
    } else {
      dispatch(errorBestBets());
    }
  };
};

export const removeBestBets = () => {
  return { type: REMOVE_BEST_BETS };
};

const setBestBets = (payload) => {
  return { type: SET_BEST_BETS, payload };
};

const errorBestBets = () => {
  return { type: ERROR_BEST_BETS };
};
