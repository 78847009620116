import styled from "styled-components";
import { THEME } from "../../../theme";

export default styled.div`
  &.span__title {
    display: block;
    text-transform: uppercase;
    white-space: nowrap;
    margin-bottom: 4px;
    color: ${THEME.TEXT_GREEN};
    background-color: ${THEME.BG_GREY};
    border-bottom: 1px solid ${THEME.BORDER_DARK_GREY};
    padding: 5px 10px;

    &-reminder {
      margin-bottom: 0;

      @media screen and (max-width: 640px) {
        text-transform: none;
        background-color: rgba(255, 255, 255);
        padding: 0;
        border-bottom: none;
      }
    }
  }
`;
