import { SET_BEST_BETS, REMOVE_BEST_BETS, ERROR_BEST_BETS } from "../types";

const initialState = {
  data: [],
  isLoading: true,
  isError: false,
};

export const bestBetsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_BEST_BETS:
      return { data: action.payload, isLoading: false };
    case ERROR_BEST_BETS:
      return { ...state, isError: true, isLoading: false };
    case REMOVE_BEST_BETS:
      return { ...initialState };
    default:
      return state;
  }
};
