import styled from "styled-components";
import { THEME } from "../../theme";

export default styled.div`
  &.header__registration {
    display: flex;
    align-items: center;
  }
  .header {
    &__link {
      display: flex;
      align-items: center;
      font-weight: bold;
      margin-left: 20px;
      color: ${THEME.TEXT_GREY_LIGHT};
      transition: all 0.4s;
      cursor: pointer;
      text-decoration: none;

      & span {
        margin-left: 5px;
        display: flex;
      }

      &:hover {
        color: ${THEME.TEXT_WHITE};
        text-decoration: none;
      }
    }
  }
`;
