import { memo } from "react";
import FooterWrapper from "./footer.style";

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <FooterWrapper className="footer">
      <div className="footer__copyright">
        <span className="footer__copyright-text">Copyright © 2007-{year}</span>
        <a href="#dffg" className="footer__copyright-link">
          OpenBall
        </a>
      </div>
    </FooterWrapper>
  );
};

export default memo(Footer);
