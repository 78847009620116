import styled from "styled-components";

export default styled.div`
  position: fixed;
  width: 100% !important;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;

  > div {
    background: #ffffff;
    box-shadow: 0px 6px 18px rgba(0, 114, 173, 0.14);
    border-radius: 20px;
    padding: 24px;
    max-height: max-content;
    display: flex;
    flex-direction: column;
    position: relative;
  }

  &.dialog-block {
    &-height {
      > div {
        max-height: 75%;
      }
    }

    &__sm > div {
      min-width: 350px;
      max-width: 350px;
    }

    &__md > div {
      min-width: 582px;
      max-width: 582px;
    }

    &__lg > div {
      min-width: 710px;
      max-width: 710px;
    }
  }
`;
