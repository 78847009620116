import { useDispatch, useSelector } from "react-redux";
import { setDisableMenu } from "../../store/actions";
import { useCallback } from "react";

export const useMenu = () => {
  const { active } = useSelector((state) => state.menu);

  const dispatch = useDispatch();

  const closed = useCallback(() => {
    dispatch(setDisableMenu());
  }, [dispatch]);

  return { active, closed };
};
