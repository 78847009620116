import { lazy, Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import Spinner from "../../components/ui/Spinner";
import { Login } from "../../pages/Login";
import { useRoutesMain } from "./use-routes-main";

const Home = lazy(() => import("../../pages/Home"));
const About = lazy(() => import("../../pages/About"));
const Rules = lazy(() => import("../../pages/Rules"));
const Teams = lazy(() => import("../../pages/Teams"));
const Team = lazy(() => import("../../pages/Team"));
const News = lazy(() => import("../../pages/News"));
const Transfers = lazy(() => import("../../pages/Transfers"));
const Calendar = lazy(() => import("../../pages/Calendar"));
const Error = lazy(() => import("../../pages/Error"));

const links = [
  { path: "/", exact: true, component: Home },
  { path: "/about", exact: false, component: About },
  { path: "/login", exact: false, component: Login },
  { path: "/rules", exact: false, component: Rules },
  { path: "/teams", exact: false, component: Teams },
  { path: "/team", exact: false, component: Team },
  { path: "/news/:id", exact: false, component: News },
  { path: "/calendar", exact: false, component: Calendar, private: true },
  { path: "/transfers", exact: false, component: Transfers, private: true },
  { path: "*", exact: false, component: Error },
];

const RoutesMain = () => {
  const { token } = useRoutesMain();
  return (
    <Suspense fallback={<Spinner />}>
      <Switch>
        {links.map((link) => (
          <Route
            key={link.path}
            path={link.path}
            exact={link.exact}
            render={(props) => {
              const TagName = token || !link.private ? link.component : Login;
              return <TagName {...props} />;
            }}
          />
        ))}
      </Switch>
    </Suspense>
  );
};

export default RoutesMain;
