import { SET_CALENDAR } from "../types";

const initialState = {
  kupons: [],
  isLoading: true,
  isError: false,
};

export const calendarReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CALENDAR:
      return { ...state, ...action.payload, isLoading: false };

    default:
      return state;
  }
};
