import styled from "styled-components";
import { THEME } from "../../theme";

export default styled.div`
  &.top__info {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: space-between;
    border-left: 1px solid ${THEME.BORDER_GREY};
    border-right: 1px solid ${THEME.BORDER_GREY};
    background: rgba(255, 255, 255);
    height: 125px;
    padding-bottom: 10px;

    @media screen and (max-width: 640px) {
      flex-direction: row;
      align-items: center;
      width: 100%;
      height: 30px;
      padding: 0;
      padding-left: 10px;
      padding-right: 10px;
      border-left: none;
      border-right: none;
      border-top: 1px solid ${THEME.BORDER_GREY};
    }
  }
  .top__info {
    &-counter {
      padding: 0px 5px;
      font-size: 20px;

      @media screen and (max-width: 640px) {
        font-size: 14px;
      }

      @media screen and (max-width: 400px) {
        padding: 0;
      }

      span {
        font-size: 40px;
        font-weight: bold;
        margin-left: 5px;
        margin-right: 5px;

        @media screen and (max-width: 640px) {
          font-size: 18px;
        }
      }
    }

    a {
      width: max-content;
    }
  }
`;
