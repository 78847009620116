import React from "react";
import SpinnerWrapper from "./spinner.style";

const Spinner = () => {
  return (
    <SpinnerWrapper>
      <div>
        <div>
          <span />
        </div>
      </div>
    </SpinnerWrapper>
  );
};

export default Spinner;
