import WrapperBlock from "../../components/ui/WrapperBlock";
import { LoginLocal } from "../../components/LoginLocal";

export const Login = () => {
  return (
    <WrapperBlock title="Авторизация">
      <LoginLocal />
    </WrapperBlock>
  );
};
