import { useSelector /* useDispatch */ } from "react-redux";
import { useMemo, useCallback } from "react";
// import { setActiveLoginForm } from "../../store/actions";

export const useHeaderTopRight = () => {
  const { user } = useSelector((state) => state.auth);

  const money = useMemo(() => {
    if (!user) {
      return "";
    }
    return Math.floor(user.money / 10000) / 100;
  }, [user]);

  //const dispatch = useDispatch();

  const login = useCallback(() => {
    // dispatch(setActiveLoginForm());
  }, []);

  return { user, money, login };
};
