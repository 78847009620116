import styled from "styled-components";

export default styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  height: 100%;
  > div {
    display: flex;
    height: 100%;
    align-items: center;
    > div {
      > span {
        width: 30px;
        height: 30px;
        display: inline-block;
        border-width: 3px;
        border-color: rgba(0, 0, 0, 0.05);
        border-top-color: #fff;
        animation: spin 1s infinite linear;
        border-radius: 100%;
        border-style: solid;
      }
    }
  }

  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
`;
