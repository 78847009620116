import MainWrapper from "./main.style";
import Menu from "../Menu";
import RoutesMain from "../../routes/RoutesMain";

export const Main = () => {
  return (
    <MainWrapper className="main">
      <Menu />
      <div className="main__contain">
        <RoutesMain />
      </div>
    </MainWrapper>
  );
};
