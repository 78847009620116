import {
  SET_BEST_FANTASY_DAY,
  REMOVE_BEST_FANTASY_DAY,
  ERROR_BEST_FANTASY_DAY,
} from "../types";

const initialState = {
  data: [],
  isLoading: true,
  isError: false,
};

export const bestFantasyDayReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_BEST_FANTASY_DAY:
      return { data: action.payload, isLoading: false };
    case ERROR_BEST_FANTASY_DAY:
      return { ...state, isError: true, isLoading: false };
    case REMOVE_BEST_FANTASY_DAY:
      return { ...initialState };
    default:
      return state;
  }
};
