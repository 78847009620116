import {
  LOAD_DATA_TRANSFER,
  CHANGE_FILTER_TRANSFER,
  BUY_PLAYER_TRANSFER,
  SELL_PLAYER_TRANSFER,
  RETURN_PLAYER_TRANSFER,
  OPEN_PLAYERS_LIST,
  CLOSE_PLAYERS_LIST,
} from "../types";

const initialState = {
  countries: [],
  positions: [],
  teams: [],
  costs: [],
  maxCost: "",
  minCost: "",
  country: 0,
  position: 0,
  team: 0,
  players: [],
  isLoading: true,
  startBalance: 0,
  currentBalance: 0,
  buyPlayers: [],
  myPlayers: [],
  openPlayers: false,
  rentPlayers: "dfdf",
};

export const transferReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_DATA_TRANSFER:
      return { ...state, ...action.payload, isLoading: false };
    case CHANGE_FILTER_TRANSFER:
      return { ...state, [action.payload.name]: action.payload.value };
    case BUY_PLAYER_TRANSFER:
      const buyPlayers = [...state.buyPlayers, action.payload];
      const currentBalance = state.currentBalance - action.payload.cost * 1000;
      const players = state.players.map((player) => {
        if (action.payload.id === player.id) {
          return { ...player, buy: true };
        }
        return player;
      });
      return {
        ...state,
        buyPlayers,
        currentBalance,
        players,
        openPlayers: false,
      };

    case SELL_PLAYER_TRANSFER:
      const newBuyPlayers = state.buyPlayers.filter(
        (player) => player.id !== action.payload.id
      );
      const newCurrentBalance =
        state.currentBalance + action.payload.cost * 1000;
      if (newBuyPlayers.length !== state.buyPlayers.length) {
        const newPlayers = state.players.map((player) => {
          if (player.id === action.payload.id) {
            return { ...player, buy: false };
          }
          return player;
        });
        return {
          ...state,
          buyPlayers: newBuyPlayers,
          currentBalance: newCurrentBalance,
          players: newPlayers,
        };
      }
      const newMyPlayers = state.myPlayers.map((player) => {
        if (player.id === action.payload.id) {
          return { ...player, sell: true };
        }
        return player;
      });
      return {
        ...state,
        currentBalance: newCurrentBalance,
        myPlayers: newMyPlayers,
      };

    case RETURN_PLAYER_TRANSFER:
      const newCurrentBalanceReturn =
        state.currentBalance - action.payload.cost * 1000;
      const newMyPlayersReturn = state.myPlayers.map((player) => {
        if (player.id === action.payload.id) {
          return { ...player, sell: false };
        }
        return player;
      });
      return {
        ...state,
        currentBalance: newCurrentBalanceReturn,
        myPlayers: newMyPlayersReturn,
      };
    case OPEN_PLAYERS_LIST:
      return { ...state, openPlayers: true, position: action.payload };
    case CLOSE_PLAYERS_LIST:
      return { ...state, openPlayers: false };
    default:
      return state;
  }
};
