import { useRef, useEffect, useState, useCallback } from "react";

const useOutsideComponent = (ref, type = "component") => {
  const [active, setActive] = useState(type !== "component");
  const open = useCallback(() => setActive(true), []);
  const close = useCallback(() => setActive(false), []);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        close();
      }
    };

    const updateSize = () => {
      close();
    };

    document.addEventListener("mousedown", handleClickOutside);
    if (type === "component") {
      window.addEventListener("resize", updateSize);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      if (type === "component") {
        window.removeEventListener("resize", updateSize);
      }
    };
  }, [ref, type, close]);

  return { active, open, close };
};

export const useDialogBlock = ({ closed }) => {
  const wrapperRef = useRef(null);
  const { active } = useOutsideComponent(wrapperRef, "alert");

  useEffect(() => {
    if (!active) {
      closed();
    }
  }, [active, closed]);
  return { wrapperRef };
};
