import { Http } from "../../service/http";
import {
  SET_NEWS_LIST_MAIN,
  REMOVE_NEWS_LIST_MAIN,
  ERROR_NEWS_LIST_MAIN,
} from "../types";
import { API } from "../../constants";
import { getDateNews } from "../../utils";

export const getNewsListMain = () => {
  return async (dispatch) => {
    const data = await Http.get(`${API}/news/list-main`);
    if (data && !data.error) {
      data.forEach((d) => {
        d.date = getDateNews(d.date);
      });
      dispatch(setNewsListMain(data));
    } else {
      dispatch(errorNewsListMain());
    }
  };
};

export const removeNewsListMain = () => {
  return { type: REMOVE_NEWS_LIST_MAIN };
};

const setNewsListMain = (payload) => {
  return { type: SET_NEWS_LIST_MAIN, payload };
};

const errorNewsListMain = () => {
  return { type: ERROR_NEWS_LIST_MAIN };
};
