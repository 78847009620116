/* import AppWrapper from "./app.style"; */
import HeaderTop from "../HeaderTop";
import Header from "../Header";
import Footer from "../Footer";
import { Main } from "../Main";
import DialogSubscription from "../Dialogs/DialogSubscription";
import SpinnerBackground from "../ui/SpinnerBackground";
import Spinner from "../ui/Spinner";
import { useApp } from "./use-app";

export const App = () => {
  /* console.log(Intl.DateTimeFormat().resolvedOptions().timeZone);
  console.log(new Date().getTimezoneOffset()); */
  const { isLoading, needLogin } = useApp();
  return (
    <>
      {(isLoading || !!needLogin) && (
        <SpinnerBackground>
          <Spinner />
        </SpinnerBackground>
      )}
      {!!!needLogin && (
        <>
          <HeaderTop />
          <Header />
          <Main />
          <Footer />
          <DialogSubscription />
        </>
      )}
    </>
  );
};
