import React from "react";

import { useLoginLocal } from "./use-login-local";

export const LoginLocal = () => {
  const {
    changeRemember,
    clicked,
    pass,
    login,
    remember,
    isError,
  } = useLoginLocal();
  return (
    <div>
      <input type="text" placeholder="Логин" ref={login} />

      <input type="password" placeholder="Пароль" ref={pass} />
      <input type="checkbox" checked={remember} onChange={changeRemember} />
      <button onClick={clicked}>Войти</button>
      {isError && <div>Неправильный логин или пароль</div>}
    </div>
  );
};
