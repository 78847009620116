export const API =
  process.env.NODE_ENV === "development"
    ? "http://api.openball.loc/api"
    : "https://api.openball.org/api";

export const month = {
  1: "январь",
  2: "февраль",
  3: "март",
  4: "апрель",
  5: "май",
  6: "июнь",
  7: "июль",
  8: "август",
  9: "сентябрь",
  10: "октябрь",
  11: "ноябрь",
  12: "декабрь",
};

export const days = {
  0: "Вск",
  1: "Пнд",
  2: "Втр",
  3: "Срд",
  4: "Чтв",
  5: "Птн",
  6: "Суб",
};
