export const LOAD_DATA = "LOAD_DATA";
export const LOAD_DATA_TRANSFER = "LOAD_DATA_TRANSFER";
export const CHANGE_FILTER_TRANSFER = "CHANGE_FILTER_TRANSFER";
export const BUY_PLAYER_TRANSFER = "BUY_PLAYER_TRANSFER";
export const SELL_PLAYER_TRANSFER = "SELL_PLAYER_TRANSFER";
export const RETURN_PLAYER_TRANSFER = "RETURN_PLAYER_TRANSFER";
export const OPEN_PLAYERS_LIST = "OPEN_PLAYERS_LIST";
export const CLOSE_PLAYERS_LIST = "CLOSE_PLAYERS_LIST";

export const OPEN_DIALOG_SUBSCRIPTION = "OPEN_DIALOG_SUBSCRIPTION";
export const CLOSE_DIALOG_SUBSCRIPTION = "CLOSE_DIALOG_SUBSCRIPTION";

export const SET_NEWS = "SET_NEWS";
export const REMOVE_NEWS = "REMOVE_NEWS";

export const SET_NEWS_LAST = "SET_NEWS_LAST";
export const ERROR_NEWS_LAST = "ERROR_NEWS_LAST";
export const REMOVE_NEWS_LAST = "REMOVE_NEWS_LAST";

export const SET_NEWS_LIST_MAIN = "SET_NEWS_LIST_MAIN";
export const REMOVE_NEWS_LIST_MAIN = "REMOVE_NEWS_LIST_MAIN";
export const ERROR_NEWS_LIST_MAIN = "ERROR_NEWS_LIST_MAIN";

export const SET_ABOUT = "SET_ABOUT";

export const SET_ACTIVE_MENU = "SET_ACTIVE_MENU";
export const SET_DISABLE_MENU = "SET_DISABLE_MENU";

export const SET_BEST_USERS = "SET_BEST_USERS";
export const REMOVE_BEST_USERS = "REMOVE_BEST_USERS";
export const ERROR_BEST_USERS = "ERROR_BEST_USERS";

export const SET_BEST_FANTASY_DAY = "SET_BEST_FANTASY_DAY";
export const REMOVE_BEST_FANTASY_DAY = "REMOVE_BEST_FANTASY_DAY";
export const ERROR_BEST_FANTASY_DAY = "ERROR_BEST_FANTASY_DAY";

export const SET_BEST_BETS = "SET_BEST_BETS";
export const REMOVE_BEST_BETS = "REMOVE_BEST_BETS";
export const ERROR_BEST_BETS = "ERROR_BEST_BETS";

export const SET_BEST_REITING = "SET_BEST_REITING";
export const REMOVE_BEST_REITING = "REMOVE_BEST_REITING";
export const ERROR_BEST_REITING = "ERROR_BEST_REITING";

export const SET_TABLES_MAIN = "SET_TABLES_MAIN";
export const REMOVE_TABLES_MAIN = "REMOVE_TABLES_MAIN";
export const ERROR_TABLES_MAIN = "ERROR_TABLES_MAIN";

export const SET_LOGIN = "SET_LOGIN";
export const REMOVE_LOGIN = "REMOVE_LOGIN";
export const START_LOGIN = "START_LOGIN";
export const START_AUTO_LOGIN = "START_AUTO_LOGIN";
export const ERROR_LOGIN = "ERROR_LOGIN";

export const SET_COUNT_MESSAGE = "SET_COUNT_MESSAGE";
export const REMOVE_COUNT_MESSAGE = "REMOVE_COUNT_MESSAGE";

export const SET_CALENDAR = "SET_CALENDAR";
