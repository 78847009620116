import { SET_NEWS_LAST, REMOVE_NEWS_LAST, ERROR_NEWS_LAST } from "../types";

const initialState = {
  news: [],
  isLoading: true,
  isError: false,
};

export const newsLastReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_NEWS_LAST:
      return { ...state, news: action.payload, isLoading: false };
    case ERROR_NEWS_LAST:
      return { ...state, isError: true, isLoading: false };
    case REMOVE_NEWS_LAST:
      return { ...initialState };
    default:
      return state;
  }
};
