import BlockWrapper from "./block-title.style";

const BlockTitle = ({
  children,
  textAlign = "left",
  link = null,
  reminder = false,
}) => {
  return (
    <BlockWrapper
      className={`span__title ${reminder ? "span__title-reminder" : ""}`}
      style={{ textAlign }}
    >
      {link ? (
        <a href={link} className="span__link">
          {children}
        </a>
      ) : (
        children
      )}
    </BlockWrapper>
  );
};

export default BlockTitle;
