import { SET_ABOUT } from "../types";

const initialState = {
  posts: [],
};

export const aboutReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ABOUT:
      console.log(action.payload);
      return { ...state, posts: [...state.posts, action.payload] };
    default:
      return state;
  }
};
