import HeaderTopWrapper from "./header-top.style";
import HeaderTopLeft from "../HeaderTopLeft";
import HeaderTopRight from "../HeaderTopRight";
/* import BlockWidth from "../BlockWidth"; */

const HeaderTop = () => {
  return (
    <HeaderTopWrapper className="header">
      <div className="header__inner">
        <HeaderTopLeft />
        <HeaderTopRight />
      </div>
    </HeaderTopWrapper>
  );
};

export default HeaderTop;
