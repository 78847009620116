import DeadlineBlockWrapper from "./deadline-block.style";
import BlockTitle from "../ui/BlockTitle";
import { useDeadlineBlock } from "./use-deadline-block";

const DeadlineBlock = () => {
  const { token } = useDeadlineBlock();
  return (
    <DeadlineBlockWrapper className="top__info">
      <BlockTitle className="span__title-reminder" textAlign="center">
        До старта тура:
      </BlockTitle>
      <div className="top__info-counter">
        менее<span>24</span>недель
      </div>

      <div>{!!token && <a href="#ssd">Сделай ставку</a>}</div>
    </DeadlineBlockWrapper>
  );
};

export default DeadlineBlock;
