import { Http } from "../../service/http";
import { API } from "../../constants";
import {
  SET_LOGIN,
  REMOVE_LOGIN,
  START_LOGIN,
  START_AUTO_LOGIN,
  ERROR_LOGIN,
} from "../types";

export const toLogin = (payload) => {
  return async (dispatch) => {
    dispatch(startLogin());
    const remember = payload.remember ? "1" : "0";
    const data = await Http.post(`${API}/auth/login`, payload);
    console.log("data", data);
    if (!data.error) {
      const payload = prepareData(data, remember);

      dispatch(setLogin(payload));
    } else {
      dispatch(errorLogin());
    }
  };
};

export const toLogout = () => {
  return async (dispatch) => {
    localStorage.removeItem("access");
    localStorage.removeItem("remember");
    dispatch(removeLogin());
  };
};

export const autoLogin = () => {
  return async (dispatch) => {
    const access = localStorage.getItem("access");
    const type = localStorage.getItem("remember") || false;
    const headers = { Authorization: `Bearer ${access}` };
    localStorage.removeItem("access");
    localStorage.removeItem("remember");

    dispatch(startAutoLogin());
    const data = await Http.post(`${API}/auth/refresh`, { type }, headers);
    if (!data.error) {
      const payload = prepareData(data, type);

      dispatch(setLogin(payload));
    } else {
      dispatch(removeLogin());
    }
  };
};

const prepareData = (data, type) => {
  const payload = {
    token: {
      access: data.access_token,
      type: data.token_type,
    },
    user: {
      money: data.money,
      photo: `https://openball.org/img/avatar/${data.photo}`,
      id: data.user,
      admin: data.admin,
    },
  };
  localStorage.setItem("access", data.access_token);
  localStorage.setItem("remember", type);
  return payload;
};

const startAutoLogin = () => {
  return { type: START_AUTO_LOGIN };
};

const removeLogin = () => {
  return { type: REMOVE_LOGIN };
};

const setLogin = (payload) => {
  return { type: SET_LOGIN, payload };
};

const errorLogin = () => {
  return { type: ERROR_LOGIN };
};

const startLogin = () => {
  return { type: START_LOGIN };
};
