import BlockTitle from "../ui/BlockTitle1";
import MenuItems from "../MenuItems";
import MenuWrapper from "./menu.style";
import { useMenu } from "./use-menu";

const Menu = () => {
  const { active, closed } = useMenu();

  return (
    <MenuWrapper className={`menu ${active ? "menu-active" : ""}`}>
      <div className="menu-shadow" onClick={closed} />
      <nav className="menu-nav">
        <div>
          <BlockTitle>Меню</BlockTitle>
        </div>
        <MenuItems type={active} closed={closed} />
      </nav>
    </MenuWrapper>
  );
};

export default Menu;
