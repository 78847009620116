import { Http } from "../../service/http";
import { API } from "../../constants";
import { getDateCalendar } from "../../utils";
import { SET_CALENDAR } from "../types";

export const getCalendar = () => {
  return async (dispatch, getState) => {
    const data = await Http.get(`${API}/calendar`);
    const id = getState().auth.user.id;

    if (data && !data.error) {
      data.kupons.forEach((kup) => {
        kup.date_block = getDateCalendar(kup.date, true);
        kup.date = getDateCalendar(kup.date);

        if (kup.pole) {
          kup.pole = "Н";
        } else if (kup.club_1 === id) {
          kup.pole = "Д";
        } else if (kup.club_2 === id) {
          kup.pole = "Г";
        } else {
          kup.pole = "";
        }
        if (kup.club_1 === id) {
          kup.sop = kup.team2;
          kup.sop_id = kup.user2;
        } else if (kup.club_2 === id) {
          kup.sop = kup.team1;
          kup.sop_id = kup.user1;
        } else {
          kup.sop = "";
          kup.sop_id = null;
        }
      });
      dispatch(setCalendar(data));
    }
  };
};

const setCalendar = (payload) => {
  return { type: SET_CALENDAR, payload };
};
