import { Http } from "../../service/http";
import {
  SET_BEST_FANTASY_DAY,
  REMOVE_BEST_FANTASY_DAY,
  ERROR_BEST_FANTASY_DAY,
} from "../types";
import { API } from "../../constants";

export const getBestFantasyDay = () => {
  return async (dispatch) => {
    const data = await Http.get(`${API}/best/fantasy-day`);
    if (data && !data.error) {
      data.forEach((d) => {
        d.date = `${d.date.slice(8, 10)}.${d.date.slice(5, 7)}`;
      });
      dispatch(setBestFantasyDay(data));
    } else {
      dispatch(errorFantasyDay());
    }
  };
};

export const removeBestFantasyDay = () => {
  return { type: REMOVE_BEST_FANTASY_DAY };
};

const setBestFantasyDay = (payload) => {
  return { type: SET_BEST_FANTASY_DAY, payload };
};

const errorFantasyDay = () => {
  return { type: ERROR_BEST_FANTASY_DAY };
};
