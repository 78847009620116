/* import Loader from "../DialogLoader"; */
import DialogBlockTitle from "../DialogBlockTitle";
import DialogBlockWrapper from "./dialog-block.style";
import { useDialogBlock } from "./use-dialog-block";
const classNames = require("classnames");

const DialogBlock = ({
  closed,
  title,
  subtitle,
  children,
  alignTitle,
  loading,
  widthBlock,
  loadingText,
  height,
}) => {
  const { wrapperRef } = useDialogBlock({ closed });

  const cn = classNames({ "dialog-block-height": height });

  const titleBlock = !loading && (
    <DialogBlockTitle
      title={title}
      subtitle={subtitle}
      textAlign={alignTitle}
    />
  );

  return (
    <DialogBlockWrapper className={`${cn} dialog-block__${widthBlock}`}>
      {loading ? (
        <div>{/* <Loader title={loadingText} /> */}</div>
      ) : (
        <div ref={wrapperRef}>
          {titleBlock}
          {children}
        </div>
      )}
    </DialogBlockWrapper>
  );
};

DialogBlock.defaultProps = {
  title: null,
  subtitle: null,
  alignTitle: "left",
  loading: false,
  widthBlock: "sm",
  loadingText: "Ожидайте...",
  height: false,
};

export default DialogBlock;
