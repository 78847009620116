import ProfileWrapper from "./profile.style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { useProfile } from "./use-profile";

export const Profile = () => {
  const {
    user,
    togglePopup,
    toggleHandler,
    logoutHandler,
    wrapperRef,
  } = useProfile();

  return (
    <ProfileWrapper ref={wrapperRef}>
      <div
        className="profile__avatar"
        style={{ backgroundImage: `url(${user.photo})`, fontSize: "12px" }}
        onClick={toggleHandler}
      />
      {togglePopup && (
        <div className="profile__window">
          <a href="#dfdf" className="profile__window-link">
            Моя команда
          </a>
          <a href="#dfdf" className="profile__window-link">
            Анкетные данные
          </a>
          <a href="#dfdf" className="profile__window-link">
            Настройки
          </a>
          <div onClick={logoutHandler} className="profile__window-link">
            <span>Выход</span>
            <FontAwesomeIcon icon={faSignOutAlt} />
          </div>
        </div>
      )}
    </ProfileWrapper>
  );
};
