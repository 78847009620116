import styled from "styled-components";

export default styled.div`
  &.main {
    display: flex;
    background-color: rgba(255, 255, 255);
    padding-left: 20px;
    padding-right: 20px;
    min-height: calc(100vh - 240px);

    @media screen and (max-width: 640px) {
      min-height: calc(100vh - 260px);
    }
  }

  .main__contain {
    width: 100%;
  }
`;
