export const THEME = {
  BORDER_GREY: "#eeeeee",
  BG_GREY: "#eeeeee",
  TEXT_GREY: "#999999",
  TEXT_GREEN: "#00aa32",
  TEXT_WHITE: "#ffffff",
  TEXT_GREY_LIGHT: "#cccccc",
  BORDER_DARK_GREY: "#999999",
  BORDER_LIGHT_GREY: "#cccccc",
  LINK_COLOR: "#23527c",

  MAIN_COLOR: "#1B609B",
  MAX_WIDTH: "1280px",
  TITLE_COLOR: "#00AA32",
  GREY_COLOR: "#eee",
  TITLE_BORDER: "#999",
  MENU_ACTIVE: "#f0ffff",
};
