import { useSelector, useDispatch } from "react-redux";
import { useCallback } from "react";
import { closeDialogSubscription } from "../../../store/actions";

export const useDialogSubscription = () => {
  const { data } = useSelector((state) => state.dialogSubscription);
  const dispatch = useDispatch();

  const closed = useCallback(() => {
    dispatch(closeDialogSubscription());
  }, [dispatch]);
  return { data, closed };
};
