import * as React from "react";
import DebtorBlockTitleWrapper from "./dialog-block-title.style";

const DialogBlockTitle = ({ title, subtitle, textAlign }) => {
  return (
    <DebtorBlockTitleWrapper style={{ textAlign }}>
      {title}
      {subtitle && <p>{subtitle}</p>}
    </DebtorBlockTitleWrapper>
  );
};
export default DialogBlockTitle;
