import { NavLink } from "react-router-dom";
import MenuItemsWrapper from "./menu-items.style";
import { useMenuItems } from "./use-menu-items";

const menus = [
  { id: 16, text: "Openball", to: "/" },
  { id: 1, text: "О лиге", to: "/about" },
  { id: 2, text: "Правила", to: "/rules" },
  { id: 3, text: "Трансфер", to: "/transfers", private: true },
  { id: 4, text: "Календарь", to: "/calendar", private: true },
  { id: 5, text: "Статистика тура", to: "/stats", private: true },
  { id: 6, text: "Fantasy", to: "/fantasy", private: true },
  { id: 7, text: "Bet Game", to: "/bet-game" },
  { id: 8, text: "Турниры", to: "/turnirs" },
  { id: 10, text: "Игровые Дни", to: "/match-days" },
  { id: 11, text: "Команды", to: "/teams" },
  { id: 12, text: "Рекорды", to: "/records", private: true },
  { id: 13, text: "Обсуждения", to: "/discuss" },
  { id: 14, text: "Online", to: "/online" },
  { id: 15, text: "Админка", to: "/admin", admin: true },
];

const MenuItems = ({ type = null, closed = null }) => {
  const { user } = useMenuItems();
  return (
    <MenuItemsWrapper>
      {menus.map((menu) => {
        if ((menu.private && !user) || (menu.admin && user && !user.admin)) {
          return null;
        }
        return (
          <li key={menu.id} className="nav-item">
            <NavLink
              to={menu.to}
              activeClassName="active"
              title={menu.text}
              onClick={type ? closed : null}
              className="nav-item--link"
            >
              {menu.text}
            </NavLink>
          </li>
        );
      })}
    </MenuItemsWrapper>
  );
};

export default MenuItems;
