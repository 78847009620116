import { useDispatch, useSelector } from "react-redux";
import { setActiveMenu, setDisableMenu } from "../../store/actions";
import { useCallback } from "react";

export const useHeaderTopLeft = () => {
  const { active } = useSelector((state) => state.menu);
  const dispatch = useDispatch();

  const clicked = useCallback(() => {
    if (active) {
      dispatch(setDisableMenu());
    } else {
      dispatch(setActiveMenu());
    }
  }, [dispatch, active]);

  return { active, clicked };
};
