import { useSelector, useDispatch } from "react-redux";
import { useMemo, useEffect } from "react";
import { autoLogin } from "../../store/actions";

export const useApp = () => {
  const { isLoading, token, autoLogin: al } = useSelector(
    (state) => state.auth
  );
  const dispatch = useDispatch();
  const needLogin = useMemo(() => {
    if (al) {
      return true;
    }
    if (!token) {
      let access = localStorage.getItem("access");

      if (access && access !== "undefined") {
        return true;
      }
    }
    return false;
  }, [token, al]);
  useEffect(() => {
    if (needLogin && !al) {
      dispatch(autoLogin());
    }
  }, [needLogin, dispatch, al]);
  return { isLoading, needLogin };
};
