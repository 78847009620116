import { SET_ACTIVE_MENU, SET_DISABLE_MENU } from "../types";

const initialState = {
  active: false,
};

export const menuReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ACTIVE_MENU:
      return { active: true };
    case SET_DISABLE_MENU:
      return { active: false };
    default:
      return state;
  }
};
