import { OPEN_DIALOG_SUBSCRIPTION, CLOSE_DIALOG_SUBSCRIPTION } from "../types";

const initialState = {
  data: null,
};

export const dialogSubscriptionReducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_DIALOG_SUBSCRIPTION:
      return { ...state, data: action.payload };
    case CLOSE_DIALOG_SUBSCRIPTION:
      return { ...initialState };
    default:
      return state;
  }
};
