import { memo } from "react";
import ButtonHamburgerWrapper from "./button-hamburger.style";

const ButtonHamburger = ({ clicked }) => {
  return (
    <ButtonHamburgerWrapper className="hamburger__btn" onClick={clicked}>
      <div className="hamburger__btn-line"></div>
      <div className="hamburger__btn-line"></div>
      <div className="hamburger__btn-line"></div>
    </ButtonHamburgerWrapper>
  );
};
export default memo(ButtonHamburger);
