import styled from "styled-components";

export default styled.div`
  &.menu {
    background-color: rgba(255, 255, 255);
    min-width: 150px;
    padding-right: 20px;

    @media screen and (max-width: 768px) {
      position: absolute; /* освобождаем место для горизонтальных новостей, чтобы они "заплывали" под мобильное меню */
      left: 0;
      top: 0;
    }
  }

  &.menu-active {
    @media screen and (max-width: 768px) {
      .menu {
        &-shadow {
          display: block;
        }
        &-nav {
          display: block;
        }
      }
    }
  }

  .menu {
    &-shadow {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.4);
      z-index: 101;
      display: none; /* прячем-показываем затемнение */
    }
    &-nav {
      position: sticky;
      top: 40px;

      @media screen and (max-width: 768px) {
        position: fixed;
        top: 0;
        bottom: 0;
        overflow-y: auto;
        min-width: 200px;
        height: 100%;
        padding: 10px;
        background-color: #f8f8f8;
        box-shadow: rgb(0 0 0 / 50%) 0px 1px 5px;
        display: none; /* прячем-показываем меню */
        z-index: 1000;
        overflow-y: auto;

        > div:first-child {
          display: none;
        }
      }
    }
  }
`;
