import { Http } from "../../service/http";
import {
  SET_TABLES_MAIN,
  REMOVE_TABLES_MAIN,
  ERROR_TABLES_MAIN,
} from "../types";
import { API } from "../../constants";

export const getTablesMain = () => {
  return async (dispatch) => {
    const data = await Http.get(`${API}/real-turnirs/main`);
    if (data && !data.error) {
      const d = data.map(({ id, name, nameTour, img, imgTour }) => {
        return {
          id,
          name: name ? name : nameTour,
          img: `https://openball.org/img/flags/${img ? img : imgTour}`,
        };
      });

      dispatch(setTablesMain(d));
    } else {
      dispatch(errorTablesMain());
    }
  };
};

export const removeTablesMain = () => {
  return { type: REMOVE_TABLES_MAIN };
};

const setTablesMain = (payload) => {
  return { type: SET_TABLES_MAIN, payload };
};

const errorTablesMain = () => {
  return { type: ERROR_TABLES_MAIN };
};
