import {
  SET_NEWS_LIST_MAIN,
  REMOVE_NEWS_LIST_MAIN,
  ERROR_NEWS_LIST_MAIN,
} from "../types";

const initialState = {
  news: [],
  isLoading: true,
  isError: false,
};

export const newsListMainReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_NEWS_LIST_MAIN:
      return { ...state, news: action.payload, isLoading: false };
    case ERROR_NEWS_LIST_MAIN:
      return { ...state, isError: true, isLoading: false };
    case REMOVE_NEWS_LIST_MAIN:
      return { ...initialState };
    default:
      return state;
  }
};
