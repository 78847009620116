import { SET_COUNT_MESSAGE, REMOVE_COUNT_MESSAGE } from "../types";

const initialState = {
  count: null,
  isLoading: true,
};

export const messageCountReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_COUNT_MESSAGE:
      return { count: action.payload, isLoading: false };
    case REMOVE_COUNT_MESSAGE:
      return { ...initialState };
    default:
      return state;
  }
};
