import styled from "styled-components";
import { THEME } from "../../theme";

export default styled.footer`
  &.footer {
    padding-bottom: 20px;
    display: flex;
    justify-content: center;
  }

  .footer {
    &__copyright {
      margin-top: 10px;
      display: flex;
      align-items: center;
      font-weight: bold;
      color: ${THEME.TEXT_GREY_LIGHT};

      &-link {
        margin-left: 10px;
        color: ${THEME.TEXT_GREY_LIGHT};
        transition: all 0.4s;

        &:hover {
          color: ${THEME.TEXT_WHITE};
        }
      }
    }
  }
`;
