import { memo } from "react";
import WrapperBlockStyle from "./wrapper-block.style";
import BlockTitle from "../BlockTitle1";

const WrapperBlock = ({
  reminder = false,
  title,
  textAlign = "left",
  link = null,
  children,
}) => {
  return (
    <WrapperBlockStyle className="main__content-wrapper">
      <BlockTitle reminder={reminder} textAlign={textAlign} link={link}>
        {title}
      </BlockTitle>
      <div className="main__content-inner">{children}</div>
    </WrapperBlockStyle>
  );
};

export default memo(WrapperBlock);
