import { memo } from "react";
import { useDialogSubscription } from "./use-dialog-subscription";
import DialogBlock from "../DialogBlock/dialog-block";

const DialogSubscription = () => {
  const { data, closed } = useDialogSubscription();

  console.log("Dialog Subscription");

  if (!data) {
    return null;
  }
  return <DialogBlock closed={closed} />;
};

export default memo(DialogSubscription);
