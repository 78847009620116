import styled from "styled-components";
import { THEME } from "../../theme";

export default styled.ul`
  .nav-item {
    border-bottom: 1px solid ${THEME.BORDER_GREY};

    &:first-child {
      display: none;

      @media screen and (max-width: 768px) {
        font-size: 18px;
        line-height: 20px;
        padding-top: 17px;
        padding-bottom: 35px;
        border-bottom: none;
        text-decoration: none;
        text-align: left;
        display: block;
      }
    }
    &--link {
      color: #23527c;
      display: block;
      padding: 5px 7px;

      text-align: left;
      transition: none;
      position: relative;

      &:hover {
        text-decoration: none;
      }

      @media screen and (max-width: 768px) {
        padding: 0;
        border-bottom: none;
      }
    }

    &:hover {
      background-color: ${THEME.BG_GREY};
    }

    @media screen and (max-width: 768px) {
      height: 30px;
      padding: 7px 14px;
      border-bottom: 1px solid ${THEME.BORDER_GREY};
    }
  }
`;
