import { month, days } from "./constants";

export const getDateNews = (date) => {
  /* const d = new Date(
    +new Date(date) - new Date().getTimezoneOffset() * 60 * 1000
  ); */
  const d = new Date(new Date(date) - new Date("1970-01-01 00:00:00"));
  return `${d.getDate()} ${month[d.getMonth() + 1]} ${d.getFullYear()}`;
};

export const getDateCalendar = (date, deadline = false) => {
  const d = new Date(new Date(date) - new Date("1970-01-01 00:00:00"));
  const day = ("0" + d.getDate()).slice(-2);
  const m = ("0" + (d.getMonth() + 1)).slice(-2);
  if (deadline) {
    const hour = ("0" + d.getHours()).slice(-2);
    const minutes = ("0" + d.getMinutes()).slice(-2);
    return `${day}.${m} ${hour}:${minutes}`;
  }
  return `${day}.${m} ${days[d.getDay()]}`;
};
