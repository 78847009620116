import { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toLogin } from "../../store/actions";

export const useLoginLocal = () => {
  const { token, isError } = useSelector((state) => state.auth);
  const [remember, setRemember] = useState(false);
  const history = useHistory();
  useEffect(() => {
    if (token) {
      history.push("/");
    }
  }, [token, history]);
  const dispatch = useDispatch();
  const clicked = () => {
    const payload = {
      email: login.current.value,
      password: pass.current.value,
      remember,
    };
    dispatch(toLogin(payload));
  };

  const changeRemember = () => {
    setRemember((state) => !state);
  };
  const login = useRef(null);
  const pass = useRef(null);
  return { changeRemember, clicked, pass, login, remember, isError };
};
