import {
  SET_LOGIN,
  REMOVE_LOGIN,
  START_LOGIN,
  START_AUTO_LOGIN,
  ERROR_LOGIN,
} from "../types";

const initialState = {
  token: null,
  user: null,
  isLoading: false,
  isError: false,
  autoLogin: false,
};

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOGIN:
      return {
        ...action.payload,
        isLoading: false,
        isError: false,
        autoLogin: false,
      };
    case REMOVE_LOGIN:
      return { ...initialState };
    case START_LOGIN:
      return { ...action.payload, isLoading: true };
    case ERROR_LOGIN:
      return { ...action.payload, isLoading: false, isError: true };
    case START_AUTO_LOGIN:
      return { ...state, autoLogin: true };
    default:
      return state;
  }
};
