import styled from "styled-components";

export default styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000;
  opacity: 0.1;
  z-index: 100;
`;
