import HeaderWrapper from "./header.style";
import DeadlineBlock from "../DeadlineBlock";
import { NavLink } from "react-router-dom";

const Header = () => {
  return (
    <HeaderWrapper className="top__section">
      <div className="top__main">
        <div>
          <NavLink to="/">
            <img
              src="/assets/img/logo.png"
              alt="Openball"
              className="top__logo"
            />
          </NavLink>
        </div>
        <div className="top__title">
          <h1>
            Футбольная
            <br />
            Лига
            <br />
            Прогнозирования
          </h1>
        </div>
      </div>
      <DeadlineBlock />
    </HeaderWrapper>
  );
};

export default Header;
