import HeaderTopRightWrapper from "./header-top-right.style";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSignInAlt,
  faUser,
  faEnvelope,
  faWallet,
} from "@fortawesome/free-solid-svg-icons";
import { useHeaderTopRight } from "./use-header-top-right";
import { Profile } from "../Profile";
import { MessageCounter } from "../MessageCounter";

const HeaderTopRight = () => {
  const { user, money } = useHeaderTopRight();
  return (
    <HeaderTopRightWrapper className="header__registration">
      {user ? (
        <>
          <a href="#dfdf" className="header__link">
            <FontAwesomeIcon icon={faEnvelope} className="fa-lg" />
            <span>
              <MessageCounter />
            </span>
          </a>
          <a href="#dfdf" className="header__link">
            <FontAwesomeIcon icon={faWallet} className="fa-lg" />
            <span>{money} Ob</span>
          </a>
          <Profile />
        </>
      ) : (
        <>
          <NavLink to="/login" className="header__link">
            <FontAwesomeIcon icon={faSignInAlt} className="fa-lg" />
            <span>Вход</span>
          </NavLink>

          <div className="header__link">
            <FontAwesomeIcon icon={faUser} className="fa-lg" />
            <span>Регистрация</span>
          </div>
        </>
      )}
    </HeaderTopRightWrapper>
  );
};

export default HeaderTopRight;
