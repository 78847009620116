import styled from "styled-components";
import { THEME } from "../../theme";

export default styled.div`
  position: relative;

  .profile {
    &__avatar {
      background-size: 35px;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      border: 2px solid ${THEME.BORDER_LIGHT_GREY};
      margin-left: 20px;

      &:hover {
        cursor: pointer;
        box-shadow: rgb(204 204 204) 0px 1px 5px;
      }
    }

    &__window {
      position: absolute;
      right: 5px;
      top: 40px;
      background: white;
      border-radius: 5px;
      border: 1px solid ${THEME.BORDER_LIGHT_GREY};
      box-shadow: rgb(0 0 0 / 50%) 0px 1px 5px;
      text-align: left;
      padding: 5px 15px;

      &-link {
        white-space: nowrap;
        padding: 5px 7px;
        display: block;
        color: ${THEME.LINK_COLOR};
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }

        &:not(:last-child) {
          border-bottom: 1px solid ${THEME.BORDER_GREY};
        }

        span {
          margin-right: 5px;
        }
      }
    }
  }
`;
