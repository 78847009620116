import BlockWrapper from "./block-title.style";

const BlockTitle = ({
  children,
  className = "",
  textAlign = "left",
  link = null,
}) => {
  const cn = `span__title ${className}`;
  return (
    <BlockWrapper className={cn} style={{ textAlign }}>
      {link ? <a href={link}>{children}</a> : children}
    </BlockWrapper>
  );
};

export default BlockTitle;
