import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { removeMessageCount, getMessageCount } from "../../store/actions";

export const useMessageCounter = () => {
  const { count, isLoading } = useSelector((state) => state.messageCount);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getMessageCount());
    const timer = setInterval(() => {
      dispatch(getMessageCount());
    }, 60000);
    return () => {
      dispatch(removeMessageCount());
      clearInterval(timer);
    };
  }, [dispatch]);
  return { count, isLoading };
};
