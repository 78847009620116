import styled from "styled-components";
import { THEME } from "../../theme";

export default styled.header`
  &.header {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    z-index: 100;
    background-color: #1f3e61;
    box-shadow: rgb(0 0 0 / 50%) 0px 1px 5px;
    text-align: center;
    color: ${THEME.TEXT_GREY_LIGHT};
    height: 40px;
  }
  .header {
    &__inner {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-right: 20px;
      height: 100%;
      max-width: 1280px;
      margin: 0 auto;
      position: relative;

      @media screen and (max-width: 768px) {
        display: flex;
        justify-content: space-between;
      }
    }
  }
`;
