import { REMOVE_LOGIN } from "../store/types";
import store from "../store";

export class Http {
  static HEADERS = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };
  static async get(url) {
    return await request(url);
  }

  static async post(url, data = {}, headers = {}) {
    try {
      return await request(url, "POST", data, headers);
    } catch (error) {
      return { error: "Server error" };
    }
  }

  static async put(url, data = {}) {
    try {
      return await request(url, "PUT", data);
    } catch (error) {
      return { error: "Server error" };
    }
  }

  static async delete(url) {
    try {
      return await request(url, "DELETE");
    } catch (error) {
      return { error: "Server error" };
    }
  }
}

const request = async (url, method = "get", data = {}, headers = {}) => {
  const token = store.getState().auth.token;
  const config = {
    method,
    headers: { ...Http.HEADERS, ...headers },
  };

  if (token) {
    config.headers.Authorization = `bearer ${token.access}`;
  }

  if (["POST", "PUT"].includes(method)) {
    config["body"] = JSON.stringify(data);
  }
  try {
    const response = await fetch(url, config);
    if (response.status === 401 && token) {
      await store.dispatch({ type: REMOVE_LOGIN });
      return null;
    } else if (response.status === 500) {
      return { error: "Server error" };
    } else {
      return await response.json();
    }
  } catch (error) {
    return { error: "Server error" };
  }
};
