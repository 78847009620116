import {
  LOAD_DATA_TRANSFER,
  CHANGE_FILTER_TRANSFER,
  BUY_PLAYER_TRANSFER,
  SELL_PLAYER_TRANSFER,
  RETURN_PLAYER_TRANSFER,
  OPEN_PLAYERS_LIST,
  CLOSE_PLAYERS_LIST,
} from "../types";

/* export const getClaims = (payload: ClaimsStartFetch) => {
    return (dispatch: Dispatch<any>) => {
        dispatch(startfetchClaims(payload));
        get(
            `${API}/claims?id=${payload.pg}&limit=${payload.limit}`,
            (response: Claim[]) => {
                dispatch(setClaims(response));
            }
        );
    };
};
 */

const countries = [
  { id: 1, title: "Англия" },
  { id: 2, title: "Германия" },
];

const teams = [
  { id: 0, title: "Все команды" },
  { id: 1, title: "Манчестер Сити", country: 1 },
  { id: 2, title: "Челси", country: 1 },
  { id: 3, title: "Бавария", country: 2 },
  { id: 4, title: "Герта", country: 2 },
];

const positions = [
  { id: 0, title: "Все позиции", min: 1, max: 1 },
  { id: 1, title: "Вратари", min: 1, max: 1 },
  { id: 2, title: "Защитники", min: 3, max: 5 },
  { id: 3, title: "Полузащитники", min: 3, max: 5 },
  { id: 4, title: "Нападающие", min: 2, max: 3 },
];

const players = [
  {
    id: 11,
    pos: 1,
    posName: "в.",
    name: "Иван Иванов1",
    team: "Дженоа",
    point: 123,
    cost: 500,
    buy: false,
  },
  {
    id: 21,
    pos: 2,
    posName: "з.",
    name: "Иван Иванов2",
    team: "Дженоа1",
    point: 123,
    cost: 1000,
    buy: true,
  },
  {
    id: 31,
    pos: 2,
    posName: "з.",
    name: "Иван Иванов3",
    team: "Дженоа2",
    point: 123,
    cost: 1500,
    buy: false,
  },
  {
    id: 41,
    pos: 2,
    posName: "з.",
    name: "Иван Иванов4",
    team: "Дженоа3",
    point: 123,
    cost: 2500,
    buy: false,
  },
  {
    id: 51,
    pos: 3,
    posName: "п.",
    name: "Иван Иванов5",
    team: "Дженоа4",
    point: 123,
    cost: 3500,
    buy: false,
  },
  {
    id: 61,
    pos: 3,
    posName: "п.",
    name: "Иван Иванов61",
    team: "Дженоа5",
    point: -123,
    cost: 4500,
    buy: false,
  },
  {
    id: 71,
    pos: 3,
    posName: "п.",
    name: "Иван Иванов7",
    team: "Дженоа6",
    point: 123,
    cost: 5500,
    buy: false,
  },
  {
    id: 81,
    pos: 4,
    posName: "н.",
    name: "Иван Иванов81",
    team: "Дженоа7",
    point: 123,
    cost: 6500,
    buy: false,
  },
  {
    id: 91,
    pos: 4,
    posName: "н.",
    name: "Иван Иванов91",
    team: "Дженоа8",
    point: 123,
    cost: 7500,
    buy: false,
  },
  {
    id: 911,
    pos: 4,
    posName: "н.",
    name: "Иван Иванов9111",
    team: "Дженоа8",
    point: 123,
    cost: 7500,
    buy: false,
  },
];

const myPlayers = [
  {
    id: 1,
    pos: 1,
    name: "Иван Иванов1",
    team: "Дженоа",
    cost: 500,
    sell: true,
  },
  {
    id: 2,
    pos: 2,
    name: "Иван Иванов2",
    team: "Дженоа1",
    cost: 1000,
    sell: false,
  },
  {
    id: 3,
    pos: 2,
    name: "Иван Иванов3",
    team: "Дженоа2",
    cost: 1500,
    sell: false,
  },
  {
    id: 4,
    pos: 2,
    name: "Иван Иванов4",
    team: "Дженоа3",
    cost: 2500,
    sell: false,
  },
  {
    id: 5,
    pos: 3,
    name: "Иван Иванов5",
    team: "Дженоа4",
    cost: 3500,
    sell: false,
  },
  {
    id: 6,
    pos: 3,
    name: "Иван Иванов6",
    team: "Дженоа5",
    cost: 4500,
    sell: false,
  },
  {
    id: 7,
    pos: 3,
    name: "Иван Иванов7",
    team: "Дженоа6",
    cost: 5500,
    sell: false,
  },
  {
    id: 8,
    pos: 4,
    name: "Иван Иванов8",
    team: "Дженоа7",
    cost: 6500,
    sell: false,
  },
  {
    id: 9,
    pos: 4,
    name: "Иван Иванов9",
    team: "Дженоа8",
    cost: 7500,
    sell: false,
  },
];

const costs = [{ id: "", text: "" }];
for (let i = 0.5; i <= 10; i = i + 0.5) {
  const cost = Number.isInteger(i) ? i.toString() + ".0" : i.toString();
  costs.push({ id: i, text: cost });
}

const startBalance = 30060000;

export const loadDataTransfer = () => {
  const currentBalance = myPlayers.reduce((data, player) => {
    if (!player.sell) {
      return data;
    }
    return data + player.cost * 1000;
  }, startBalance);
  myPlayers.forEach((player) => {
    player.sell_requered = player.sell;
  });

  const payload = {
    countries,
    teams,
    positions,
    players,
    costs,
    country: countries[0].id,
    startBalance,
    currentBalance,
    myPlayers,
  };
  return { type: LOAD_DATA_TRANSFER, payload };
};

export const changeFilterTransfer = (payload) => {
  return { type: CHANGE_FILTER_TRANSFER, payload };
};

export const buyPlayerTransfer = (payload) => {
  return { type: BUY_PLAYER_TRANSFER, payload };
};

export const sellPlayerTransfer = (payload) => {
  return { type: SELL_PLAYER_TRANSFER, payload };
};

export const returnPlayerTransfer = (payload) => {
  return { type: RETURN_PLAYER_TRANSFER, payload };
};

export const openPLayersList = (payload) => {
  return { type: OPEN_PLAYERS_LIST, payload };
};

export const closePLayersList = () => {
  return { type: CLOSE_PLAYERS_LIST };
};

export const saveTransfer = () => {
  console.log("sddf");
  return (dispatch, getState) => {
    console.log(getState().transfer);
  };
};
