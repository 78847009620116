import { Http } from "../../service/http";
import {
  SET_BEST_REITING,
  REMOVE_BEST_REITING,
  ERROR_BEST_REITING,
} from "../types";
import { API } from "../../constants";

export const getBestReiting = () => {
  return async (dispatch) => {
    const data = await Http.get(`${API}/best/reiting`);
    if (data && !data.error) {
      data.forEach((d, i) => {
        d.index = `${i + 1}.`;
      });
      dispatch(setBestReiting(data));
    } else {
      dispatch(errorBestReiting());
    }
  };
};

export const removeBestReiting = () => {
  return { type: REMOVE_BEST_REITING };
};

const setBestReiting = (payload) => {
  return { type: SET_BEST_REITING, payload };
};

const errorBestReiting = () => {
  return { type: ERROR_BEST_REITING };
};
