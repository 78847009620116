import { Http } from "../../service/http";
import { SET_COUNT_MESSAGE, REMOVE_COUNT_MESSAGE } from "../types";
import { API } from "../../constants";

export const getMessageCount = () => {
  return async (dispatch, getState) => {
    const data = await Http.get(`${API}/message`);
    if (data && !data.error) {
      if (data !== getState().messageCount.count) {
        dispatch(setMessageCount(data));
      }
    } else {
      if (getState().messageCount.count === null) {
        dispatch(setMessageCount(0));
      }
    }
  };
};

export const removeMessageCount = () => {
  return { type: REMOVE_COUNT_MESSAGE };
};

const setMessageCount = (payload) => {
  return { type: SET_COUNT_MESSAGE, payload };
};
