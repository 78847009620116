import styled from "styled-components";
import { THEME } from "../../../theme";

export default styled.span`
  &.span {
    &__title {
      display: block;
      text-transform: uppercase;
      white-space: nowrap;
      margin-bottom: 4px;
      color: ${THEME.TEXT_GREEN};
      background-color: ${THEME.BG_GREY};
      border-bottom: 1px solid ${THEME.BORDER_DARK_GREY};
      padding: 5px 10px;

      &-reminder {
        border-bottom: none;
        margin-bottom: 0;

        @media screen and (max-width: 640px) {
          text-transform: none;
          margin-bottom: 0;
          background-color: rgba(255, 255, 255);
          padding: 0;
        }
      }
    }

    &__link {
      color: ${THEME.TEXT_GREEN};
      &:hover {
        text-decoration: underline;
      }
    }
  }

  a {
    color: ${THEME.TITLE_COLOR};
  }

  @media screen and (max-width: 640px) {
    &.deadline-title {
      background-color: #fff;
      border: 0;
      text-transform: none;
      padding-left: 0px;
      padding-bottom: 0px;
    }
  }
`;
