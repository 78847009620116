import styled from "styled-components";
/* import { THEME } from "../../theme"; */

export default styled.div`
  &.top__section {
    background-image: url("/assets/img/top_bckrd.PNG");
    background-size: auto;
    background-color: white;
    background-repeat: repeat-x;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 60px;
    padding-left: 20px;
    padding-right: 20px;
    flex-direction: row;

    @media screen and (max-width: 640px) {
      flex-direction: column;
      align-items: start;
    }
  }

  .top {
    &__main {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__title h1 {
      display: table;
      word-spacing: 60px;
      line-height: 1.5;
      font-size: 22px;
      text-transform: capitalize;
      font-style: italic;
      margin-left: 15px;
      color: #1b609b;

      @media screen and (max-width: 400px) {
        font-size: 18px;
      }
    }
    &__logo {
      @media screen and (max-width: 400px) {
        width: 100px;
      }
    }
  }
`;
