import styled from "styled-components";

export default styled.div`
  &.main__content-wrapper {
    width: 100%;
    padding-bottom: 10px;
  }
  .main__content-inner {
    display: flex;
    padding: 0 10px 0;
  }
`;
