import { Http } from "../../service/http";
import { SET_BEST_USERS, REMOVE_BEST_USERS, ERROR_BEST_USERS } from "../types";
import { API, month } from "../../constants";

export const getBestUsers = () => {
  return async (dispatch) => {
    const data = await Http.get(`${API}/best/users`);
    if (data && !data.error) {
      data.forEach((d) => {
        d.date = `${d.year} ${month[d.month]}`;
      });
      dispatch(setBestUsers(data));
    } else {
      dispatch(errorBestUsers());
    }
  };
};

export const removeBestUsers = () => {
  return { type: REMOVE_BEST_USERS };
};

const setBestUsers = (payload) => {
  return { type: SET_BEST_USERS, payload };
};

const errorBestUsers = () => {
  return { type: ERROR_BEST_USERS };
};
