import styled from "styled-components";
import { THEME } from "../../../theme";

export default styled.div`
  &.hamburger__btn {
    margin-left: 20px;
    min-width: 29px;
    height: 24px;
    border: 1px solid ${THEME.BORDER_DARK_GREY};
    padding: 5px 5px 4px;
    border-radius: 5px;
    cursor: pointer;
    display: none;
    -webkit-tap-highlight-color: transparent;

    @media screen and (max-width: 768px) {
      display: block;
    }
  }
  .hamburger__btn-line {
    height: 2px;
    margin-bottom: 3px;
    background-color: ${THEME.BORDER_DARK_GREY};
  }
`;
