import { useMessageCounter } from "./use-message-counter";
import { SpinnerSmall } from "../ui/SpinnerSmall";

export const MessageCounter = () => {
  const { count, isLoading } = useMessageCounter();
  console.log("Render counter");
  if (isLoading) {
    return <SpinnerSmall />;
  }

  if (count) {
    return count;
  }
  return null;
};
