import { useSelector, useDispatch } from "react-redux";
import { useState, useCallback, useRef, useEffect } from "react";
import { toLogout } from "../../store/actions";

export const useProfile = () => {
  const [togglePopup, setTogglePopup] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const wrapperRef = useRef(null);

  const [active, setActive] = useState(false);
  const open = useCallback(() => setActive(true), []);
  const close = useCallback(() => setActive(false), []);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        close();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [close]);

  useEffect(() => {
    if (!active && togglePopup) {
      setTogglePopup(false);
    }
  }, [active, togglePopup]);

  const dispatch = useDispatch();
  const toggleHandler = useCallback(() => {
    setTogglePopup((state) => {
      if (!state) {
        open();
      }
      return !state;
    });
  }, [open]);

  const logoutHandler = useCallback(() => {
    dispatch(toLogout());
  }, [dispatch]);

  return { user, togglePopup, toggleHandler, logoutHandler, wrapperRef };
};
