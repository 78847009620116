import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { authReducer as auth } from "./reducers/auth";
import { mainReducer as main } from "./reducers/main";
import { transferReducer as transfer } from "./reducers/transfer";
import { dialogSubscriptionReducer as dialogSubscription } from "./reducers/dialog-subscription";
import { newsLastReducer as newsLast } from "./reducers/news-last";
import { newsListMainReducer as newsListMain } from "./reducers/news-list-main";
import { aboutReducer as about } from "./reducers/about";
import { menuReducer as menu } from "./reducers/menu";
import { bestUsersReducer as bestUsers } from "./reducers/best-users";
import { bestFantasyDayReducer as bestFantasyDay } from "./reducers/best-fantasy-day";
import { bestBetsReducer as bestBets } from "./reducers/best-bets";
import { bestReitingReducer as bestReiting } from "./reducers/best-reiting";
import { tablesMainReducer as tablesMain } from "./reducers/tables-main";
import { messageCountReducer as messageCount } from "./reducers/message-count";
import { calendarReducer as calendar } from "./reducers/calendar";

const rootReducer = combineReducers({
  about,
  auth,
  bestBets,
  bestFantasyDay,
  bestReiting,
  bestUsers,
  calendar,
  main,
  menu,
  messageCount,
  newsLast,
  newsListMain,
  tablesMain,
  transfer,
  dialogSubscription,
});

const composeEnhancers =
  process.env.NODE_ENV === "development" &&
  window["__REDUX_DEVTOOLS_EXTENSION_COMPOSE__"]
    ? window["__REDUX_DEVTOOLS_EXTENSION_COMPOSE__"]
    : compose;

const createStoreWithMiddleware = composeEnhancers(applyMiddleware(thunk))(
  createStore
);

export default createStoreWithMiddleware(rootReducer);
